<template>
  <div class="order-banner" id="module-stats">
    <div class="ctmDashboardCardRow navbar-expand-lg navbar">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        Show Stats
        <span class="navbar-toggler-icon">
          <svg enable-background="new 0 0 26 26" id="Слой_1" version="1.1" viewBox="0 0 26 26" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g>
              <path d="M24.25,12.25H1.75C1.3359375,12.25,1,12.5859375,1,13s0.3359375,0.75,0.75,0.75h22.5   c0.4140625,0,0.75-0.3359375,0.75-0.75S24.6640625,12.25,24.25,12.25z" fill="#1D1D1B"/>
              <path d="M1.75,7.75h22.5C24.6640625,7.75,25,7.4140625,25,7s-0.3359375-0.75-0.75-0.75H1.75   C1.3359375,6.25,1,6.5859375,1,7S1.3359375,7.75,1.75,7.75z" fill="#1D1D1B"/>
              <path d="M24.25,18.25H1.75C1.3359375,18.25,1,18.5859375,1,19s0.3359375,0.75,0.75,0.75h22.5   c0.4140625,0,0.75-0.3359375,0.75-0.75S24.6640625,18.25,24.25,18.25z" fill="#1D1D1B"/>
            </g>
          </svg>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="justify-content: space-between;">
        <div class="mbStatesBlock">
          <div class="statesWrapBlock">
            <p>Orders<span></span></p>
            <div class="ctmDashboardCardItem lightBlueCart" v-if="statsLoader">
              <vue-skeleton-loader
                type="rect"
                :width="220"
                :height="60"
                animation="fade"
              />
            </div>
            <div class="ctmDashboardCardItem lightBlueCart" v-else>
              <div class="orderStatesTxtBlock">
                <div class="statesTxtField">
                  <el-tooltip class="box-item" effect="dark" :content="mainStats.orders.today ? `${mainStats.orders.today}` : `0`" placement="top">
                    <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.orders.today ) }}</h4>
                  </el-tooltip>
                  <p>Today</p>
                </div>
                <div class="statesTxtField weekDetailTxt">
                  <el-tooltip class="box-item" effect="dark" :content="`${mainStats.orders.weekly}`" placement="top">
                    <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.orders.weekly ) }}</h4>
                  </el-tooltip>
                  <p>7 Days</p>
                </div>
                <div class="statesTxtField monthlyDetailTxt">
                  <el-tooltip class="box-item" effect="dark" :content="mainStats.orders.monthly ? `${mainStats.orders.monthly}` : `0`" placement="top">
                    <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.orders.monthly ) }}</h4>
                  </el-tooltip>
                  <p>30 Days</p>
                </div>
              </div>
              <div class="cardIconWrap">
                <img src='../assets/images/ordersStatesImg.svg' alt="">
              </div>
            </div>
          </div>
          <div class="statesWrapBlock">
            <p>Delayed Orders<span></span></p>
            <div class="ctmDashboardCardItem lightYellowCart" v-if="statsLoader">
              <vue-skeleton-loader
                type="rect"
                :width="245"
                :height="60"
                animation="fade"
              />
            </div>
            <div class="ctmDashboardCardItem lightYellowCart" v-else>
              <div class="cardTxtWrap">
                <el-tooltip class="box-item" effect="dark" :content="mainStats.delayed_orders.count ? `${mainStats.delayed_orders.count}` : `0`" placement="top">
                  <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.delayed_orders.count) }}</h4>
                </el-tooltip>
                <p>Orders delayed (total)</p>
              </div>
              <div class="cardIconWrap">
                <img src='../assets/images/delayedOrdersImg.svg' alt="">
              </div>
            </div>
          </div>
          <div class="statesWrapBlock">
            <p>Stock <span></span></p>
            <div class="ctmDashboardCardItem lightRedCart" v-if="statsLoader">
              <vue-skeleton-loader
                type="rect"
                :width="220"
                :height="60"
                animation="fade"
              />
            </div>
            <div class="ctmDashboardCardItem lightRedCart" v-else>
              <div class="cardTxtWrap">
                <el-tooltip class="box-item" effect="dark" :content="mainStats.low_stock_products.count ? `${mainStats.low_stock_products.count}` : `0`" placement="top">
                  <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.low_stock_products.count) }}</h4>
                </el-tooltip>
                <p>Low stock products</p>
              </div>
              <div class="cardIconWrap">
                <img src='../assets/images/delayedOrdersImg.svg' alt="">
              </div>
            </div>
          </div>
          <div class="statesWrapBlock">
            <p>Conversion 
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.511 9.726" v-if="mainStats.conversion.turnover == 'decr'">
                  <g id="arrow-right-short" transform="translate(18.634 -9) rotate(90)">
                    <path id="Path_52149" data-name="Path 52149" d="M18.177,10.3a.608.608,0,0,1,.861,0l3.647,3.647a.608.608,0,0,1,0,.861l-3.647,3.647a.609.609,0,0,1-.861-.861l3.217-3.217-3.217-3.217a.608.608,0,0,1,0-.861Z" transform="translate(-4.137)" fill="#fd4d5d" fill-rule="evenodd"/>
                    <path id="Path_52150" data-name="Path 52150" d="M9,17.483a.608.608,0,0,1,.608-.608h7.9a.608.608,0,1,1,0,1.216h-7.9A.608.608,0,0,1,9,17.483Z" transform="translate(0 -3.104)" fill="#fd4d5d" fill-rule="evenodd"/>
                  </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.512 9.726" v-if="mainStats.conversion.turnover == 'incr'">
                  <g id="arrow-right-short" transform="translate(0 9.726) rotate(-90)">
                    <path id="Path_52149" data-name="Path 52149" d="M.179,8.333a.608.608,0,0,0,.861,0L4.686,4.686a.608.608,0,0,0,0-.861L1.039.178a.609.609,0,0,0-.861.861L3.4,4.256.179,7.473a.608.608,0,0,0,0,.861Z" transform="translate(4.861 0)" fill="#1ec2c2" fill-rule="evenodd"/>
                    <path id="Path_52150" data-name="Path 52150" d="M0,.608a.608.608,0,0,0,.608.608h7.9A.608.608,0,0,0,8.509,0H.608A.608.608,0,0,0,0,.608Z" transform="translate(0 3.648)" fill="#1ec2c2" fill-rule="evenodd"/>
                  </g>
                </svg>
              </span>
            </p>
            <div class="ctmDashboardCardItem lightBlueCart" v-if="statsLoader">
              <vue-skeleton-loader
                type="rect"
                :width="220"
                :height="60"
                animation="fade"
              />
            </div>
            <div class="ctmDashboardCardItem lightBlueCart" v-else>
              <div class="cardTxtWrap">
                <el-tooltip class="box-item" effect="dark" :content="`${mainStats.conversion.sales} / ${mainStats.conversion.visits}`" placement="top">
                  <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.conversion.sales) }} <span class="statesLine">/</span> 
                      {{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.conversion.visits) }}
                    <span v-if="mainStats.conversion.sales > 0 && mainStats.conversion.visits > 0">{{ (mainStats.conversion.percentage).toFixed(1) }}%</span>
                  </h4>
                </el-tooltip>
                <p>Sales / Visitors (30 days)</p>
              </div>
              <div class="cardIconWrap">
                <img src='../assets/images/conversionImg.svg' alt="">
              </div>
            </div>
          </div>
          <div class="statesWrapBlock">
            <p>Sales <span></span></p>
            <div class="ctmDashboardCardItem lightYellowCart" v-if="statsLoader">
              <vue-skeleton-loader
                type="rect"
                :width="220"
                :height="60"
                animation="fade"
              />
            </div>
            <div class="ctmDashboardCardItem lightYellowCart" v-else>
              <div class="cardTxtWrap">
                <el-tooltip class="box-item" effect="dark" :content="`${mainStats.sales.thirtyDays} / ${mainStats.sales.lifeTime}`" placement="top">
                  <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.sales.thirtyDays) }}
                    <span class="statesLine">/</span> {{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.sales.lifeTime) }}
                  </h4>
                </el-tooltip>
                <p>30 days / Life time</p>
              </div>
              <div class="cardIconWrap">
                <img src='../assets/images/salesImg.svg' alt="">
              </div>
            </div>
          </div>
          <div class="statesWrapBlock">
            <p>Expense vs Sales (30 days) 
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.511 9.726" v-if="mainStats.expenses_sales_conversion.turnover == 'decr'">
                  <g id="arrow-right-short" transform="translate(18.634 -9) rotate(90)">
                    <path id="Path_52149" data-name="Path 52149" d="M18.177,10.3a.608.608,0,0,1,.861,0l3.647,3.647a.608.608,0,0,1,0,.861l-3.647,3.647a.609.609,0,0,1-.861-.861l3.217-3.217-3.217-3.217a.608.608,0,0,1,0-.861Z" transform="translate(-4.137)" fill="#fd4d5d" fill-rule="evenodd"/>
                    <path id="Path_52150" data-name="Path 52150" d="M9,17.483a.608.608,0,0,1,.608-.608h7.9a.608.608,0,1,1,0,1.216h-7.9A.608.608,0,0,1,9,17.483Z" transform="translate(0 -3.104)" fill="#fd4d5d" fill-rule="evenodd"/>
                  </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.512 9.726" v-if="mainStats.expenses_sales_conversion.turnover == 'incr'">
                  <g id="arrow-right-short" transform="translate(0 9.726) rotate(-90)">
                    <path id="Path_52149" data-name="Path 52149" d="M.179,8.333a.608.608,0,0,0,.861,0L4.686,4.686a.608.608,0,0,0,0-.861L1.039.178a.609.609,0,0,0-.861.861L3.4,4.256.179,7.473a.608.608,0,0,0,0,.861Z" transform="translate(4.861 0)" fill="#1ec2c2" fill-rule="evenodd"/>
                    <path id="Path_52150" data-name="Path 52150" d="M0,.608a.608.608,0,0,0,.608.608h7.9A.608.608,0,0,0,8.509,0H.608A.608.608,0,0,0,0,.608Z" transform="translate(0 3.648)" fill="#1ec2c2" fill-rule="evenodd"/>
                  </g>
                </svg>
              </span>
            </p>
            <div class="ctmDashboardCardItem lightRedCart" v-if="statsLoader">
              <vue-skeleton-loader
                type="rect"
                :width="220"
                :height="60"
                animation="fade"
              />
            </div>
            <div class="ctmDashboardCardItem lightRedCart" v-else>
              <div class="cardTxtWrap">
                <el-tooltip class="box-item" effect="dark" :content="`${mainStats.expenses_sales_conversion.expenses} / ${mainStats.expenses_sales_conversion.sales}`" placement="top">
                  <h4>{{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.expenses_sales_conversion.expenses) }} 
                    <span class="statesLine">/</span> 
                    {{ Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(mainStats.expenses_sales_conversion.sales) }} 
                    <span v-if="mainStats.expenses_sales_conversion.expenses > 0 && mainStats.expenses_sales_conversion.sales > 0">
                    {{ (mainStats.expenses_sales_conversion.percentage).toFixed(1) }}%</span>
                  </h4>
                </el-tooltip>
                <p>Expenses / Sales</p>
              </div>
              <div class="cardIconWrap">
                <img src='../assets/images/saleImg.svg' alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name:'moduleStats',
  data:()=>({
    mainStats:{
      orders: {
        today: 0,
        weekly: 0,
        monthly: 0
      },
      delayed_orders: {
        count: 0
      },
      low_stock_products: {
        count: 0
      },
      conversion: {
        sales: 0,
        visits: 0,
        percentage: 0,
        turnover: null
      },
      sales: {
        thirtyDays: 0,
        lifeTime: 0
      },
      expenses_sales_conversion: {
        expenses: 0,
        sales: 0,
        percentage: 0,
        turnover: null
      }
    },
  }),
  computed:{
    ...mapGetters({
      Stats:'main_stats/stats',
      statsLoader:'main_stats/isPending',
    }),
  },
  watch:{
    'Stats':{
      handler:function(val){

        if(val){

          this.mainStats = val;

        }else{

          this.mainStats = {
            orders: {
              today: 0,
              weekly: 0,
              monthly: 0
            },
            delayed_orders: {
              count: 0
            },
            low_stock_products: {
              count: 0
            },
            conversion: {
              sales: 0,
              visits: 0,
              percentage: 0,
              turnover: null
            },
            sales: {
              thirtyDays: 0,
              lifeTime: 0
            },
            expenses_sales_conversion: {
              expenses: 0,
              sales: 0,
              percentage: 0,
              turnover: null
            }
          }

        }

      },
      deep:true
    },
  },
  methods:{

  },
  async beforeMount(){

    if(!this.Stats || this.Stats.length == 0){

      this.$store.dispatch('main_stats/fetchStatsRequest');

    }else{

      this.mainStats = this.Stats;
      
    }

  },
}
</script>

<style>
  /* States Css Start Here */
  .ctmDashboardCardRow{
    display: flex;
    align-items: center;
    gap: 15.4px;
    flex-wrap: wrap;
  }
  .ctmDashboardCardRow .navbar-toggler{
    margin-right:0;
    margin-left: auto;
    padding: 0;
  }
  .statesWrapBlock{
    width: 15.83%;
  }
  .statesWrapBlock p{
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .statesWrapBlock p span{
    display: inline-flex;
    align-items: center;
    margin-left: 5.5px;
  }
  .statesWrapBlock p span svg{
    display: inline-block;
    width: 8.511px;
    height: 9.726px;
    margin-right: 2.5px;
  }
  .statesWrapBlock p span svg:nth-last-child(1){
    margin-right: 0px;
  }
  .ctmDashboardCardItem{
    width: 100%;
    padding: 12px 17px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ctmDashboardCardItem.lightBlueCart{
    background-color: #1EC2C2;
  }
  .ctmDashboardCardItem.lightYellowCart{
    background-color: #E0E847;
  }
  .ctmDashboardCardItem.lightRedCart{
    background-color: #FD4D5D;
  }
  .orderStatesTxtBlock{
    width: 144px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .statesTxtField{
    text-align: center;
  }
  .statesTxtField h4{
    font-size: 30px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField p{
    font-size: 12px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.weekDetailTxt{
    opacity: 0.9;
  }
  .statesTxtField.weekDetailTxt h4{
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.weekDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.monthlyDetailTxt{
    opacity: 0.8;
  }
  .statesTxtField.monthlyDetailTxt h4{
    font-size: 19px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.monthlyDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .cardTxtWrap h4{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    display: inline-flex;
    align-items: flex-end;
  }
  .cardTxtWrap h4 span{
    display: inline-block;
    margin-bottom: 0px !important;
    color: #15223D;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: -5px;
    left: 3px
  }
  .cardTxtWrap p{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
  }
  .cardIconWrap{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: #fff;
    padding: 12px;
  }
  .lightBlueCart .cardIconWrap{
    background: #2CCBCB;
  }
  .lightYellowCart .cardIconWrap{
    background: #D4DC38;
  }
  .lightYellowCart .cardTxtWrap h4{
    color: #15223D;
  }
  .lightYellowCart .cardTxtWrap p{
    color: #15223D;
  }
  .lightRedCart .cardIconWrap{
    background: #EF3F4F;
  }
  .cardIconWrap img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* States Css End Here */
</style>